<script>
    import { apiUrl } from "@/utils";

    export default {
        name: "ThePersonalBannerCard",
        props: {
            banner: {
                type: Object,
                required: true,
            },
        },
        computed: {
            getUrl() {
                if (this.banner.pages.length) {
                    const page = this.banner.pages.find((page) => {
                        return page.link.includes(this.$root.$_nameDev);
                    });
                    return page?.link || this.banner.defaultPage;
                }
                return this.banner.defaultPage;
            },
        },
        methods: {
            apiUrl,
        },
    };
</script>

<template>
    <div class="the-personal-banner-card">
        <a
            :href="getUrl"
            class="the-personal-banner-card__title"
            :target="banner.targetBlank ? '_blank' : ''"
        >
            {{ banner.name }}
        </a>
        <a
            class="the-personal-banner-card__link"
            :href="getUrl"
            :target="banner.targetBlank ? '_blank' : ''"
        >
            <div class="the-personal-banner-card__image">
                <img :src="apiUrl(banner.image)" alt="" />
            </div>
        </a>
    </div>
</template>

<style lang="scss">
    .the-personal-banner-card {
        @include bp($bp-desktop-sm) {
            &:hover {
                .the-personal-banner-card__title {
                    color: $personal-accent-color;
                }
                .the-personal-banner-card__image {
                    transform: scale(1.03);
                }
            }
        }
    }

    .the-personal-banner-card__title {
        display: block;
        margin-bottom: 5px;
        padding-left: 15px;
        color: $personal-card-text-color;
        font-size: 14px;
        font-weight: $personal-card-banner-text-weight;
        transition: color 0.2s ease-in;
        @include bp($bp-desktop-sm) {
            padding-left: 20px;
            font-size: 16px;
        }
    }

    .the-personal-banner-card__link {
        display: block;
        border-radius: $personal-box-radius;
        @include bp($bp-desktop-sm) {
            padding: 5px;
            box-shadow: 0 0 50px #f2f2f5;
        }
    }

    .the-personal-banner-card__image {
        position: relative;
        padding-bottom: 75%;
        border-radius: inherit;
        overflow: hidden;
        transition: transform 0.2s ease-in;
        img {
            @include center-absolute(0);
            width: 100%;
        }
    }
</style>
