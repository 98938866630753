<script>
    import { declineOfNumber } from "@/utils";
    import ThePersonalButton from "@/ThePersonal/components/ui/ThePersonalButton";
    import ThePersonalPromocodeCard from "@/ThePersonal/ThePersonalPromocodeCard";
    import ThePersonalBannerCard from "@/ThePersonal/ThePersonalBannerCard";
    import ThePersonalSkeletonBox from "@/ThePersonal/ThePersonalSkeletonBox";
    import ThePersonalModal from "@/ThePersonal/components/ui/ThePersonalModal";
    import ThePersonalDiscountsInfo from "@/ThePersonal/ThePersonalDiscountsInfo";

    export default {
        name: "ThePersonalDiscounts",
        components: {
            ThePersonalDiscountsInfo,
            ThePersonalSkeletonBox,
            ThePersonalBannerCard,
            ThePersonalButton,
            ThePersonalPromocodeCard,
            ThePersonalModal,
        },
        data() {
            return {
                promoCoupons: [],
                promoSales: [],
                isLoadedPromoCoupons: false,
                isLoadedPromoSales: false,
                promoCouponsLimit: 6,
                promoSalesLimit: 4,
                copiedPromoCoupon: "",
                isOpenedInfoModal: false,
            };
        },
        computed: {
            computePromoCoupons() {
                return this.promoCoupons.slice(0, this.promoCouponsLimit);
            },
            computePromoSales() {
                return this.promoSales.slice(0, this.promoSalesLimit);
            },
            subtitles() {
                return {
                    codesTitle: declineOfNumber(this.promoCoupons.length, [
                        "доступен",
                        "доступно",
                        "доступно",
                    ]),
                    codes: declineOfNumber(this.promoCoupons.length, [
                        "промокод",
                        "промокода",
                        "промокодов",
                    ]),
                    banners: declineOfNumber(this.promoSales.length, [
                        "акция",
                        "акции",
                        "акций",
                    ]),
                };
            },
        },
        mounted() {
            this.getProfilePromoCoupons();
            this.getProfilePromoSales();
            if (this.$route.hash) {
                this.scrollTo();
            }
        },
        methods: {
            getProfilePromoCoupons() {
                return this.$store.profileApi.getProfilePromoCoupons().then((response) => {
                    this.promoCoupons = response;
                    this.isLoadedPromoCoupons = true;
                });
            },
            getProfilePromoSales() {
                return this.$store.profileApi.getProfilePromoSales().then((response) => {
                    this.promoSales = response;
                    this.isLoadedPromoSales = true;
                });
            },
            increasePromoCouponsLimit() {
                this.promoCouponsLimit = this.promoCoupons.length;
            },
            increasePromoSalesLimit() {
                this.promoSalesLimit = this.promoSales.length;
            },
            copyCouponHandler(code) {
                this.copiedPromoCoupon = code;
            },
            scrollTo() {
                setTimeout(() => {
                    const element = this.$refs.salesSection;
                    const offset = window.innerWidth > 992 ? 70 : 100;
                    const top = element.getBoundingClientRect().top - offset;
                    window.scrollTo({ top, behavior: "smooth" });
                }, 300);
            },
        },
    };
</script>

<template>
    <div class="the-personal-discounts">
        <section class="the-personal-discounts__section">
            <div class="the-personal-discounts__head">
                <div class="the-personal-discounts__title">Персональные промокоды</div>
                <div class="the-personal-discounts__subtitle">
                    <span class="mobile-hidden">Вам</span> {{ subtitles.codesTitle }}
                    {{ promoCoupons.length }}
                    <span class="mobile-hidden">{{ subtitles.codes }}</span>
                </div>
            </div>

            <div class="the-personal-discounts__info">
                <div class="the-personal-discounts__info-col">
                    <div class="the-personal-discounts__info-title">
                        Скидки на любые покупки в наших интернет-магазинах:
                    </div>
                    <div class="the-personal-discounts__info-links">
                        <a href="https://madrobots.ru/" target="_blank">madrobots.ru</a>
                        <a href="https://xd-design.ru/" target="_blank">xd-design.ru</a>
                        <a href="https://picooc.ru/" target="_blank">picooc.ru</a>
                        <a href="https://petoneer.ru/" target="_blank">petoneer.ru</a>
                        <a href="https://miku-russia.ru/" target="_blank">miku-russia.ru</a>
                        <a href="https://chipolo.ru/" target="_blank">chipolo.ru</a>
                        <a href="https://bivan.ru/" target="_blank">bivan.ru</a>
                        <a href="https://ledgerwalletrussia.ru/" target="_blank">
                            ledgerwalletrussia.ru
                        </a>
                        <a href="https://zavtra.co/" target="_blank"> zavtra.co </a>
                    </div>
                </div>

                <the-personal-button @click="isOpenedInfoModal = true">
                    Порядок применения промокодов
                </the-personal-button>
            </div>

            <div class="the-personal-discounts__notification">
                Нажми на промокод, чтобы скопировать!
            </div>
            <div class="the-personal-discounts__inner" v-show="isLoadedPromoCoupons">
                <div
                    class="the-personal-discounts__card"
                    v-for="coupon in computePromoCoupons"
                    :key="coupon.id"
                >
                    <the-personal-promocode-card
                        :coupon="coupon"
                        :is-copied="coupon.code === copiedPromoCoupon"
                        @on-copy="copyCouponHandler"
                    ></the-personal-promocode-card>
                </div>
            </div>
            <the-personal-skeleton-box
                v-show="!isLoadedPromoCoupons"
                :list="6"
                type="promocode"
            ></the-personal-skeleton-box>
            <the-personal-button
                v-if="promoCouponsLimit < promoCoupons.length"
                class="the-personal-discounts__button"
                @click="increasePromoCouponsLimit"
            >
                Еще промокодов
            </the-personal-button>
        </section>

        <section class="the-personal-discounts__section" ref="salesSection">
            <div class="the-personal-discounts__head">
                <div class="the-personal-discounts__title">Текущие акции</div>
                <div class="the-personal-discounts__subtitle">
                    <span class="mobile-hidden">Сейчас проходит </span>
                    <span class="desktop-hidden">действует</span>
                    {{ promoSales.length }}
                    {{ subtitles.banners }}
                </div>
            </div>
            <div class="the-personal-discounts__inner">
                <div
                    class="the-personal-discounts__card the-personal-discounts__card--banner"
                    v-for="sale in computePromoSales"
                    :key="sale.id"
                >
                    <the-personal-banner-card :banner="sale"></the-personal-banner-card>
                </div>
            </div>
            <the-personal-button
                v-if="promoSalesLimit < promoSales.length"
                class="the-personal-discounts__button"
                @click="increasePromoSalesLimit"
            >
                Еще акции
            </the-personal-button>
        </section>

        <the-personal-modal
            class="the-personal-modal-promocodes"
            max-width="700"
            :is-opened="isOpenedInfoModal"
            @on-close="isOpenedInfoModal = false"
        >
            <template #title>Порядок действий для применения промокода</template>
            <template #content>
                <the-personal-discounts-info></the-personal-discounts-info>
            </template>
        </the-personal-modal>
    </div>
</template>

<style lang="scss">
    .the-personal-modal-promocodes {
        .base-popup__content {
            margin-top: 20vh;
            @include bp($bp-desktop-sm) {
                margin-top: 0;
            }
        }
    }

    .the-personal-discounts__section {
        &:not(:last-of-type) {
            margin-bottom: 40px;
        }
        @include bp($bp-desktop-sm) {
            &:not(:last-of-type) {
                margin-bottom: 60px;
            }
        }
    }

    .the-personal-discounts__head {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 15px;
        padding-left: 15px;
        @include bp($bp-desktop-sm) {
            padding-left: 20px;
        }
    }

    .the-personal-discounts__title {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        color: $personal-title-color;
        font-size: 19px;
        font-weight: 700;
        line-height: 30px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 5px;
            font-size: 25px;
            font-weight: $personal-title-weight;
            line-height: 30px;
        }
    }

    .the-personal-discounts__subtitle {
        margin-left: 5px;
        color: $personal-subtitle-color;
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        white-space: nowrap;
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .the-personal-discounts__info {
        position: relative;
        z-index: 1;
        margin-bottom: 10px;
        margin-top: -5px;
        padding: 15px;
        color: #555770;
        font-size: 12px;
        font-weight: $personal-widget-link-weight;
        line-height: 20px;
        border-radius: $personal-box-radius--small;
        box-shadow: $personal-common-shadow-min;
        border: $personal-common-border-full;

        @include bp($bp-desktop-sm) {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            padding: 20px;
            font-size: 16px;
            border-radius: $personal-box-radius;
            box-shadow: $personal-common-shadow-max;
            button {
                max-width: 370px;
            }
        }
    }

    .the-personal-discounts__info-col {
        @include bp($bp-desktop-sm) {
            flex-grow: 1;
            padding-right: 30px;
        }
    }

    .the-personal-discounts__info-title {
        margin-bottom: 8px;
        color: $personal-card-title-color;
    }

    .the-personal-discounts__info-links {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 100px;
        margin-bottom: 5px;
        a {
            margin-bottom: 10px;
            color: $personal-widget-link-color;
            font-size: 14px;
            white-space: nowrap;
            transition: color 0.2s ease-in;
        }

        @include bp($bp-desktop-sm) {
            flex-direction: row;
            max-height: 100%;
            margin: 0 -5px;
            a {
                margin: 0 5px;
                &:hover {
                    color: darken($personal-widget-link-color, 10%);
                    text-decoration: underline;
                }
            }
        }
    }

    .the-personal-discounts__notification {
        margin-bottom: 10px;
        padding: 8px 10px 10px;
        color: $personal-notification-color;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        background-color: $personal-notification-fill;
        border-radius: $personal-box-radius;
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .the-personal-discounts__inner {
        @include bp($bp-desktop-sm) {
            position: relative;
            z-index: 2;
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
        }
    }

    .the-personal-discounts__card {
        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
        &:only-child {
            margin-bottom: 10px;
        }
        @include bp($bp-desktop-sm) {
            flex-basis: 50%;
            max-width: 50%;
            padding: 0 10px;
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }

    .the-personal-discounts__card--banner {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
        @include bp($bp-desktop-sm) {
            &:not(:last-of-type) {
                margin-bottom: 24px;
            }
        }
    }

    .the-personal-discounts__button {
        margin-top: 20px;
        @include bp($bp-desktop-sm) {
            max-width: 280px;
            margin-top: 0;
        }
    }
</style>
