<script>
    export default {
        name: "ThePersonalDiscountsInfo",
    };
</script>

<template functional>
    <div class="the-personal-discounts-info">
        <span>Каждый промокод можно применить только один раз</span>
        <ol>
            <li>Скопируйте промокод</li>
            <li>Перейдите на сайт</li>
            <li>Добавьте товары в корзину</li>
            <li>Вставьте промокод при оформлении заказа</li>
            <li>Ура, скидка!</li>
        </ol>
    </div>
</template>

<style lang="scss">
    .the-personal-discounts-info {
        span {
            display: block;
            margin-bottom: 30px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            @include bp($bp-desktop-sm) {
                font-size: 16px;
            }
        }
        ol {
            counter-reset: number;
            list-style: none;
            margin-bottom: 15px;
            padding: 24px;
            color: $personal-card-text-color;
            font-size: 16px;
            font-weight: 300;
            border: 1px solid $personal-card-border-color;
            border-radius: $personal-box-radius--small;
            @include bp($bp-desktop-sm) {
                padding: 0;
                border: none;
            }
        }
        li {
            position: relative;
            &:not(:last-child) {
                margin-bottom: 15px;
            }
            &::before {
                content: counter(number);
                counter-increment: number;
                margin-right: 8px;
                color: $personal-modal-accent-color;
                font-size: 18px;
                font-weight: 800;
            }
            @include bp($bp-desktop-sm) {
                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }
        }
    }
</style>
