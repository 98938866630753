<script>
    import { apiUrl, isMobile } from "@/utils";
    import ThePersonalIcon from "@/ThePersonal/components/ThePersonalIcon";
    import ThePersonalTooltip from "@/ThePersonal/components/ui/ThePersonalTooltip";

    export default {
        name: "ThePersonalPromocodeCard",
        components: { ThePersonalTooltip, ThePersonalIcon },
        props: {
            coupon: {
                type: Object,
                required: true,
            },
            isCopied: Boolean,
        },
        data() {
            return {
                timeout: null,
                isTooltipShow: false,
                isSelfClicked: false,
            };
        },
        computed: {
            tooltipText() {
                return this.isCopied ? "Уже скопировано" : "Нажми, чтобы скопировать";
            },
            name() {
                return this.coupon.personalOffer?.name || "";
            },
            brand() {
                const codes = {
                    s1: "madrobots",
                    s4: "ugears",
                    s5: "zavtra",
                    s6: "bivan",
                    s7: "xd-design",
                    s8: "picooc",
                    s9: "ledger",
                    t1: "nanoleaf",
                    t2: "chipolo",
                    t4: "halatence",
                    t5: "travel-blue",
                    t6: "petoneer",
                    t7: "mi-ku",
                };
                return codes[this.coupon.personalOffer?.site?.code] || "madrobots";
            },
            image() {
                return this.coupon.personalOffer?.banner
                    ? apiUrl(this.coupon.personalOffer.banner)
                    : apiUrl(`/include/the_personal/product-${this.brand}.png`);
            },
            url() {
                return this.coupon.personalOffer?.catalogPage;
            },
        },
        methods: {
            copyToClipboard(code) {
                const input = document.createElement("input");
                input.value = code;
                document.body.appendChild(input);
                input.select();
                document.execCommand("copy");
                document.body.removeChild(input);

                this.$emit("on-copy", code);
            },
            mouseEnterHandler() {
                if (!isMobile(992)) {
                    this.timeout = setTimeout(() => {
                        this.isTooltipShow = true;
                    }, 3000);
                }
            },
            mouseLeaveHandler() {
                if (!isMobile(992)) {
                    clearTimeout(this.timeout);
                    this.isTooltipShow = false;
                }
            },
            apiUrl,
        },
    };
</script>

<template>
    <div
        class="the-personal-promocode-card"
        :class="{ 'self-clicked': isSelfClicked }"
        @click.self="isSelfClicked = true"
        @mouseleave="isSelfClicked = false"
    >
        <div class="the-personal-promocode-card__arrow">
            <the-personal-icon name="arrow"></the-personal-icon>
            <div v-show="isTooltipShow">
                <the-personal-tooltip width="120" position-x="right">
                    Перейти в раздел
                </the-personal-tooltip>
            </div>
        </div>
        <a
            :href="url"
            class="the-personal-promocode-card__title"
            target="_blank"
            @mouseenter="mouseEnterHandler"
            @mouseleave="mouseLeaveHandler"
        >
            {{ name }}
        </a>
        <a :href="url" class="the-personal-promocode-card__logo" target="_blank">
            <img :src="apiUrl(`/include/the_personal/logo-${brand}.svg`)" alt="" />
        </a>
        <button
            class="the-personal-promocode-card__code outline"
            :class="{ copied: isCopied }"
            @click="copyToClipboard(coupon.code)"
        >
            {{ coupon.code }}
            <span class="the-personal-promocode-card__icon">
                <the-personal-icon name="copy"></the-personal-icon>
                <span class="the-personal-promocode-card__tooltip">
                    <the-personal-tooltip width="175" position-x="right">
                        {{ tooltipText }}
                    </the-personal-tooltip>
                </span>
            </span>
        </button>
        <a :href="url" class="the-personal-promocode-card__image" target="_blank">
            <img :src="image" alt="" />
        </a>
    </div>
</template>

<style lang="scss">
    .the-personal-promocode-card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 166px;
        padding: 10px 15px 15px;
        background-color: #fff;
        border: $personal-border-width solid $personal-common-border-color;
        border-radius: $personal-box-radius;
        box-shadow: $personal-common-shadow-min;
        transition: transform 0.2s ease-in, box-shadow 0.2s ease-in, border-color 0.2s ease-in;

        @include bp($bp-desktop-sm) {
            min-height: 234px;
            padding: 20px;
            box-shadow: $personal-common-shadow-max;
            will-change: transform;
            &.self-clicked {
                .the-personal-promocode-card__tooltip {
                    display: block;
                }
            }
            &::after {
                content: "";
                @include center-absolute(-1px);
                border-radius: inherit;
                transition: box-shadow 0.2s ease-in;
                pointer-events: none;
            }
            &:hover {
                border-color: $personal-card-border-color;
                box-shadow: none;
                transform: translate(-4px, -4px) translateZ(0);
                &::after {
                    box-shadow: 4px 4px 0 $personal-card-border-color;
                }
            }
            &:active {
                border-color: $personal-card-border-color--active;
                transform: translate(0);
                &::after {
                    box-shadow: none;
                }
            }
        }
    }

    .the-personal-promocode-card__arrow {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 12px;
        height: 12px;
        svg.icon-arrow {
            width: 12px;
            height: 12px;
            fill: $personal-card-border-color;
            vertical-align: top;
        }
        @include bp($bp-desktop-sm) {
            top: 25px;
            right: 20px;
        }
    }

    .the-personal-promocode-card__image {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 132px;
        height: 112px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }
        @include bp($bp-desktop-sm) {
            width: 207px;
            height: 174px;
        }
    }

    .the-personal-promocode-card__title {
        position: relative;
        z-index: 1;
        max-width: 200px;
        margin-bottom: 10px;
        color: $personal-card-title-color;
        font-size: 14px;
        font-weight: $personal-card-title-weight;
        line-height: 16px;
        @include bp($bp-desktop-sm) {
            max-width: 100%;
            width: 100%;
            padding-right: 40px;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            transition: color 0.2s ease-in;
            &:hover {
                color: #1c1c28;
            }
        }
    }

    .the-personal-promocode-card__icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
    }

    .the-personal-promocode-card__tooltip {
        display: none;
    }

    .the-personal-promocode-card__logo {
        position: relative;
        z-index: 1;
        width: 145px;
        height: 40px;
        img {
            max-height: 100%;
        }
        @include bp($bp-desktop-sm) {
            width: 185px;
            height: 60px;
        }
    }

    .the-personal-promocode-card__code {
        position: relative;
        z-index: 1;
        margin-top: auto;
        padding-right: 25px;
        color: $personal-card-promocode-color;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
        transition: color 0.2s ease-in;
        svg.icon-copy {
            width: 18px;
            height: 18px;
            fill: currentColor;
            transform: rotate(-90deg);
        }
        &.copied {
            color: $personal-success-color;
        }
        @include bp($bp-desktop-sm) {
            &:hover {
                .the-personal-promocode-card__tooltip {
                    display: block;
                }
            }
        }
    }
</style>
