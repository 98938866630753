<script>
    export default {
        name: "ThePersonalSkeletonBox",
        props: {
            list: Number,
            type: {
                type: String,
                required: true,
                validator: (v) => ["address", "promocode"].includes(v),
            },
            direction: {
                type: String,
                default: "row",
            },
        },
    };
</script>

<template>
    <div class="the-personal-skeleton-box" :class="[`${type}-type`, `${direction}-direction`]">
        <div
            class="the-personal-skeleton-box__item the-personal-skeleton"
            v-for="item in list"
            :key="item"
        ></div>
    </div>
</template>

<style lang="scss">
    .the-personal-skeleton-box {
        &.address-type {
            .the-personal-skeleton-box__item {
                height: 240px;
                @include bp($bp-desktop-sm) {
                    height: 250px;
                }
            }
        }
        &.promocode-type {
            .the-personal-skeleton-box__item {
                height: 160px;
                @include bp($bp-desktop-sm) {
                    height: 230px;
                }
            }
        }

        @include bp($bp-desktop-sm) {
            &.row-direction {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -10px;
                .the-personal-skeleton-box__item {
                    flex-basis: calc(50% - 20px);
                    max-width: calc(50% - 20px);
                    margin: 0 10px 20px;
                }
            }
            &.column-direction {
                .the-personal-skeleton-box__item {
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    .the-personal-skeleton-box__item {
        margin-bottom: 10px;
        background-color: #e5e5ec;
        border-radius: $personal-box-radius;
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
</style>
